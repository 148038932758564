import React, { useState, useEffect } from 'react';
import Radium from 'radium';
import 'antd/dist/antd.css';
import { Button, Card, Divider, Input, message, Spin } from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import Boxes from './common/backgrounds/boxes/Boxes';
import { get_order_for_signature } from './api/cloudfunctions/orders';
import SignWaiver from './components/SignWaiver';


function App() {

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<any>(null);
  const [inputText, setInputText] = useState<string>('');

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    const orderId = window.location.pathname.slice(1);
    setLoading(true);
    try {
      const {data} = await get_order_for_signature({orderId});
      if (data) {
        setOrder(data);
      }
    }
    catch (err: any) {
      console.log(err.message);
    }
    finally {
      setLoading(false);
    }
  }

  const goToTrackingURL = (inputText: string) => {
    window.location.href = inputText
  }

  const renderSignature = () => {
    if (order?.signedSignature) {
      return (
        <Card style={comStyles().contents}>
          <div style={comStyles().title}>Shippie</div>
          <div>Order Has Been Signed</div>
          <Divider/>
          <p style={comStyles().text}>This order has been successfully signed by <b>{order.fullName}</b></p>
          <p style={comStyles().text}><b>Signature: </b></p>
          <img src={order.signedSignature} style={{borderWidth: '2px', borderColor: 'green', borderStyle: 'solid', padding: 20, width: '100%'}} />
          <br/><br/>
          <p style={comStyles().text}><b>Notes: </b></p>
          <p style={comStyles().text}>{order.notes}</p>
          <div style={{ height: `30px` }} />
          <div style={comStyles().footer}>Shippie Technologies Inc</div>
        </Card>
      )
    }
    else if (order) {
      return (
        <SignWaiver formattedAddress={order.formattedAddress} orderId={order.orderId} fullName={order.fullName} />
      )
    }
    else {
      return (
        <Card style={comStyles().contents}>
          <div style={comStyles().title}>Shippie</div>
          <div>Waive Order Signature</div>
          <Divider/>
          <Input style={comStyles().input} placeholder='Order Tracking ID' onChange={(e) => setInputText(e.target.value)} />
          <div style={comStyles().buttonContainer}>
            <Button style={comStyles().button} 
              disabled={loading || inputText.length === 0} 
              loading={loading} 
              type='primary'
              size="large"
              icon={<SearchOutlined />}
              onClick={() => goToTrackingURL(inputText)}>
              Waive Signature
            </Button>
          </div>
          <div style={{ height: `30px` }} />
          <div style={comStyles().footer}>Shippie Technologies Inc</div>
        </Card>
      )
    }
  }

  return (
    <>
      <Boxes />
      <div style={comStyles().container}>
        {
          loading
          ?
          <Spin />
          :
          renderSignature()
        }
      </div>
    </>
  );
}

const RadiumHOC = Radium(App);
export default RadiumHOC;

// the JS function that returns Radium JS styling
const comStyles: () => {[key: string]: React.CSSProperties} = () => {
  return {
    container: {
      display: `flex`,
      alignItems: `center`,
      justifyContent: `center`,
      height: `100vh`,
      width: `100vw`,
      position: `absolute`,
      top: 0,
      left: 0,
    },
    contents: {
      display: `flex`,
      flexDirection: `column`,
      width: `400px`,
      minHeight: `100%`,
      maxHeight: `100%`,
      margin: `20px 0px`,
      padding: `50px 0px`,
      overflowY: `scroll`,
      textAlign: 'center'
    },
    text: {
      textAlign: 'left',
      fontSize: '16px'
    },
    title: {
      fontSize: `2rem`,
      fontWeight: `bold`,
    },
    footer: {
      display: `flex`,
      flexDirection: `row`,
      justifyContent: `center`,
      alignItems: `center`,
      width: `100%`,
    },
    input: {
      margin: `15px 0px`,
      padding: `10px 20px`,
      height: `50px`,
    },
    buttonContainer: {
      alignItems: `center`,
      display: `flex`,
      flexDirection: `row`,
      justifyContent: `center`,
    },
    button: {
      padding: `10px 20px`,
      margin: `11px 0px`,
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
      height: `50px`,
    },
  };
};