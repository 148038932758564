import React, { useState } from 'react';
import Radium from 'radium';
import { Divider, Input, Button, Modal, message, Card } from 'antd';
import { save_signature_to_order } from '../api/cloudfunctions/orders';
import SignatureCanvas from 'react-signature-canvas'
const { TextArea } = Input;

function SignWaiver({orderId, formattedAddress, fullName}: any) {

  const [loading, setLoading] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');
  const [signatureURL, setSignatureURL] = useState('');
  const [showModal, setShowModal] = useState<boolean>(false);

  const [signature, setSignature] = useState<any>({});

  const onConfirmPickup = () => {
    if (!signature.isEmpty()) {
      setSignatureURL(signature.getTrimmedCanvas().toDataURL('image/png'));
      setShowModal(true)
    }
    else {
      message.error('Missing Signature')
    }
  }

  const submitSignature = async () => {
    setLoading(true);
    try {
      await save_signature_to_order({orderId, signedSignature: signatureURL, notes});
      message.success('Success');
      window.location.reload();
    }
    catch (err: any) {
      message.error(err.message);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <Card style={comStyles().contents}>
      <p style={comStyles().title}>Waive In Person Signature</p>
      <Divider />
      <div style={comStyles().body}>
        <b>To: {formattedAddress}</b>
        <p style={comStyles().cardText}>
          A signature is required for this order on delivery. If you would like to waive the in-person signature, please sign below.
        </p>

        <p style={comStyles().inputTitle}>Sign: </p>
        <div style={{borderWidth: '2px', borderColor: 'green', borderStyle: 'solid'}}>
          <SignatureCanvas penColor='black' ref={(ref) => setSignature(ref)} canvasProps={{width: '320', height: 200, className: 'sigCanvas'}} />
        </div>
        <Button style={{float: 'right'}} onClick={() => signature.clear()} >clear</Button>

        <div style={{marginTop: 40}}>
          <p style={comStyles().inputTitle}>Drop off Notes: </p>
          <TextArea placeholder='Notes for the delivery driver ' rows={4} onChange={(e) => setNotes(e.target.value)}/>
        </div>
        
        <p style={comStyles().cardText}>
          Your driver will leave the package at your doorstep or concierge if nothing is written in the notes.
        </p>
        
      </div>
      <Button onClick={onConfirmPickup} type='primary' style={{float: 'right'}}>Confirm</Button>
      
      <Modal 
        title="Confirm Signature" 
        visible={showModal}
        onOk={submitSignature}
        onCancel={() => setShowModal(false)}
        okText="Confirm"
        cancelText="Cancel"
        okButtonProps={{ disabled:  loading  }}
      >
        <p style={{textAlign: 'center'}}>By confirming, you agree that this signature was signed by <b>{fullName}</b> <br/><br/> And the order may be left by your doorstep or concierge at the following location unless stated otherwise: <br/> <br/> <b>{formattedAddress}</b></p>
      </Modal>
    </Card>
  );
}

const RadiumHOC = Radium(SignWaiver);
export default RadiumHOC;

// the JS function that returns Radium JS styling
const comStyles: () => {[key: string]: React.CSSProperties} = () => {
  return {
    contents: {
      display: `flex`,
      flexDirection: `column`,
      width: `400px`,
      minHeight: `100%`,
      maxHeight: `100%`,
      margin: `20px 0px`,
      padding: `50px 0px`,
      overflowY: `scroll`,
    },
    body: {
      textAlign: 'center'
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      textAlign: 'center'
    },
    inputTitle: {
      textAlign: 'left',
      fontWeight: 'bold',
      fontSize: 16,
    },
    cardText: {
      fontSize: 16,
      marginBottom: 20,
      marginTop: 20,
    },
    row: {
      marginTop: 20
    },
    pickers: {
      width: '100%'
    }
  };
};